import React from "react";
import logoAynnaka from "../assets/icons/aynnaka-logo-rect.jpg";
import logoBasm from "../assets/icons/basm-logo-rect.jpg";
import logoGoldexWording from "../assets/icons/goldex-wording-logo-rect.jpg";
import logoJaal from "../assets/icons/jaal-logo-rect.jpg";
import logoTravlink from "../assets/icons/travlink-logo-rect.jpg";
import logoOliveseed from "../assets/icons/oliveseed-logo.jpg";
import logoUca from "../assets/icons/uca-logo.jpg";
import logoUm6p from "../assets/icons/um6p-logo.jpg";
import logoFNM from "../assets/icons/fnm-logo.jpg";

function PartnerWidget() {
  return (
    <div className="w-full bg-white p-5 mt-5 z-20">
      <div className="container mx-auto">
        <div className="flex md:flex-row flex-col justify-center px-3 ">
          <div className="flex flex-row justify-center ">
            <img
              className="m-1 md:w-24 w-32 "
              src={logoFNM}
              alt="Fondation National des Musees"
            />
            <img
              className="m-1 md:w-24 w-32 "
              src={logoOliveseed}
              alt="Oliveseed"
            />
            <img className="m-1 md:w-24 w-32 " src={logoJaal} alt="Jaal" />
          </div>
          <div className="flex flex-row justify-center ">
            <img className="m-1 md:w-24 w-32 " src={logoBasm} alt="Basm" />
            <img
              className="m-1 md:w-24 w-32 "
              src={logoGoldexWording}
              alt="Goldex Wording"
            />
            <img
              className="m-1 md:w-24 w-32 "
              src={logoAynnaka}
              alt="Aynnaka"
            />
          </div>
          <div className="flex flex-row justify-center ">
            <img className="m-1 md:w-24 w-32 " src={logoTravlink} alt="Basm" />
            <img className="m-1 md:w-24 w-32 " src={logoUca} alt="UCA" />
            <img className="m-1 md:w-24 w-32 " src={logoUm6p} alt="UM^P" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerWidget;
