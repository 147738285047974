import React, { useState } from "react";
import iconLogo from "../assets/icons/mebf-lg.jpg";
import { useLocation } from "react-router-dom";

function HeaderWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  return (
    <div className="bg-black w-full md:relative fixed  top-0  md:z-50 z-50">
      <div className="w-full bg-[#1a1c20] lg:hidden md:block hidden ">
        <div className="container mx-auto flex flex-row justify-end py-2  ">
          <a
            className="mx-2"
            target={"_blank"}
            rel="noreferrer"
            href="https://www.instagram.com/marrakechbookfestival/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              className="size-7  text text-white"
            >
              <path
                fill="#fff"
                d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
              ></path>
            </svg>
          </a>
          <a
            className="mx-2 "
            target={"_blank"}
            rel="noreferrer"
            href="https://www.facebook.com/marrakechenglishbookfestival"
          >
            <svg
              className="size-7 text text-white"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="container mx-auto pt-5 px-5 text-white ">
        <div className="w-full flex flex-row items-center">
          <div>
            <a href="/">
              <img
                src={iconLogo}
                className=" md:size-32 size-12 md:mb-0 mb-1"
              />
            </a>
          </div>
          <div className="flex-1 flex flex-col justify-end">
            <div className="flex-1"></div>
            <div className="font-bold lg:text-5xl md:text-5xl sm:text-xl text-sm line-clamp-2 tracking-widest">
              <a href="/">Marrakech English Book Festival</a>
            </div>
            <div className="lg:flex flex-row hidden mt-5">
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/about-us"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/about-us">About</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/authors"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/authors">Authors</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/programme"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/programme">Programme</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/passes-tickets"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/passes-tickets">Passes & Tickets</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/contact"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/contact">Contact</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/credits"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/credits">Credits</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/costa-goldex-book-award"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/costa-goldex-book-award">Costa Goldex Book Award</a>
              </div>
              <div
                className={`mx-2 text-xs hover:dfont-bold cursor-pointer ${
                  location.pathname === "/photo-gallery"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                }  hover:text-[#b85557]`}
              >
                <a href="/photo-gallery"> Photo Gallery</a>
              </div>
            </div>
          </div>
          <div className="lg:flex hidden flex-row">
            <a
              className="mx-2"
              target={"_blank"}
              rel="noreferrer"
              href="https://www.instagram.com/marrakechbookfestival/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                className="size-7  text text-white"
              >
                <path
                  fill="#fff"
                  d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                ></path>
              </svg>
            </a>
            <a
              className="mx-2 "
              target={"_blank"}
              rel="noreferrer"
              href="https://www.facebook.com/marrakechenglishbookfestival"
            >
              <svg
                className="size-7 text text-white"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="lg:hidden flex flex-row items-center">
            <svg
              onClick={() => {
                setIsOpen(true);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6 cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </div>
          {/*  */}
          <div
            className={`bg-black overflow-hidden  fixed z-50 top-0  min-h-screen right-0 duration-500 transition-all ${
              isOpen ? " w-2/3" : "w-0"
            }`}
          >
            <div className=" flex flex-row justify-end px-3 bg-black">
              {/* <a
                className="flex flex-row items-center"
                onClick={() => setIsOpen(false)}
                href="/"
              >
                <img
                  className="h-20"
                  alt="Marrakech English Book Festival"
                  src={iconLogo}
                />
                <a className="text-xl font-bold" href="/">
                  Marrakech English Book Festival
                </a>
              </a> */}
              <svg
                onClick={() => setIsOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10 text-white m-5 cursor-pointer hover:text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="w-full my-8 mx-5 flex flex-col items-left">
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/about-us"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/about-us">About</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/authors"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/authors">Authors</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/programme"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/programme">Programme</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/passes-tickets"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/passes-tickets">Passes & Tickets</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/contact"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/contact">Contact</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/credits"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/credits">Credits</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/costa-goldex-book-award"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/costa-goldex-book-award">Costa Goldex Book Award</a>
              </div>
              <div
                className={` mx-2 font-bold my-3 text-base  ${
                  location.pathname === "/photo-gallery"
                    ? "text-[#b85557]"
                    : "text-[#b8bbc4]"
                } hover:text-[#b85557]`}
              >
                <a href="/photo-gallery"> Photo Gallery</a>
              </div>
              <div className="flex  flex-row my-3">
                <a
                  className="mx-2"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.instagram.com/marrakechbookfestival/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 24 24"
                    className="size-7 mx-2 text text-white"
                  >
                    <path
                      fill="#fff"
                      d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                    ></path>
                  </svg>
                </a>
                <a
                  className="mx-2"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.facebook.com/marrakechenglishbookfestival"
                >
                  <svg
                    className="size-7 mx-2 text text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderWidget;
