import React from "react";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import FooterWidget from "../widgets/FooterWidget";
import PartnerWidget from "../widgets/PartnerWidget";

function InfoScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto mt-5 md:w-max w-full  mb-5 min-h-[70vh] flex flex-col items-center justify-center ">
            <div className="bg-white flex flex-col items-center justify-center px-5 py-10 mt-5 md:w-max w-full mb-5  ">
              <p className="text-center  font-medium text-2xl p-3   w-max px-3 mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-info.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow md:text-xl text-sm font-bold text-white"
                >
                  Download info about the award
                </a>
              </p>
              <p className="text-center font-medium  p-3 text-2xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-entry-form.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow  md:text-xl text-sm font-bold  text-white "
                >
                  Download entry form
                </a>
              </p>
              <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Send 3 hard copies of your book to:
                <br />
                <div className="text-base  ">
                  Marrakech English Book Association C/O Still Images <br />
                  Résidence Achraf
                  <br /> Appt 10 10 Rue Ibn Atya
                  <br /> Guéliz Marrakech 40020 Morocco
                </div>
              </p>
              <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Email your completed entry form + pdf copy of your book to:
                <br />
                <a
                  className="text-[#a15656]"
                  target={"_blank"}
                  rel="noreferrer"
                  href="mailto:admin@mebookfest.com"
                >
                  admin@mebookfest.com
                </a>
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default InfoScreen;
