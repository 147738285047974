import logo from "./logo.svg";
import "./App.css";
import HomeScreen from "./screen/HomeScreen";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AboutScreen from "./screen/AboutScreen";
import InfoScreen from "./screen/InfoScreen";
import AuthorsScreen from "./screen/AuthorsScreen";
import ProgrammeScreen from "./screen/ProgrammeScreen";
import PassesTicketsScreen from "./screen/PassesTicketsScreen";
import ContactScreen from "./screen/ContactScreen";
import CreditScreen from "./screen/CreditScreen";
import CostaGoldexBookAwardScreen from "./screen/CostaGoldexBookAwardScreen";
import PhotoGalleryScreen from "./screen/PhotoGalleryScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/about-us",
    element: <AboutScreen />,
  },
  {
    path: "/detail-info",
    element: <InfoScreen />,
  },
  {
    path: "/authors",
    element: <AuthorsScreen />,
  },
  {
    path: "/programme",
    element: <ProgrammeScreen />,
  },
  {
    path: "/passes-tickets",
    element: <PassesTicketsScreen />,
  },
  {
    path: "/contact",
    element: <ContactScreen />,
  },
  {
    path: "/credits",
    element: <CreditScreen />,
  },
  {
    path: "/costa-goldex-book-award",
    element: <InfoScreen />,
  },
  {
    path: "/photo-gallery",
    element: <PhotoGalleryScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
